(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@turf/bbox-clip"), require("@turf/bbox-polygon"), require("@turf/union"), require("@turf/invariant"), require("@turf/helpers"), require("@turf/boolean-intersects"), require("@turf/boolean-within"), require("@turf/intersect"), require("quadbin"), require("h3-js"));
	else if(typeof define === 'function' && define.amd)
		define(["@turf/bbox-clip", "@turf/bbox-polygon", "@turf/union", "@turf/invariant", "@turf/helpers", "@turf/boolean-intersects", "@turf/boolean-within", "@turf/intersect", "quadbin", "h3-js"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactCore"] = factory(require("@turf/bbox-clip"), require("@turf/bbox-polygon"), require("@turf/union"), require("@turf/invariant"), require("@turf/helpers"), require("@turf/boolean-intersects"), require("@turf/boolean-within"), require("@turf/intersect"), require("quadbin"), require("h3-js"));
	else
		root["cartoReactCore"] = factory(root["@turf/bbox-clip"], root["@turf/bbox-polygon"], root["@turf/union"], root["@turf/invariant"], root["@turf/helpers"], root["@turf/boolean-intersects"], root["@turf/boolean-within"], root["@turf/intersect"], root["quadbin"], root["h3-js"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__72__, __WEBPACK_EXTERNAL_MODULE__855__, __WEBPACK_EXTERNAL_MODULE__28__, __WEBPACK_EXTERNAL_MODULE__764__, __WEBPACK_EXTERNAL_MODULE__830__, __WEBPACK_EXTERNAL_MODULE__97__, __WEBPACK_EXTERNAL_MODULE__55__, __WEBPACK_EXTERNAL_MODULE__956__, __WEBPACK_EXTERNAL_MODULE__835__, __WEBPACK_EXTERNAL_MODULE__502__) {
return 